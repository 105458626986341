import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

import DefaultTemplate from "../templates/default-template";
import CenterFrame from "../components/common/center-frame";
import { useLocationInitialization } from "../utils/hooks";

const NotFoundPage = ({ className, data, pageContext: { locale }, location }) => {
    const [i18nData, setI18nData] = useState(null);

    useLocationInitialization(location);

    useEffect(() => {
        setI18nData(data.allDatoCmsI18N.nodes[0]);
    }, [data]);

    return (
        <>
            {i18nData ? (
                <DefaultTemplate
                    i18nData={i18nData}
                    locale={locale}
                    pathname={location.pathname}
                    colored
                >
                    <div className={className}>
                        <CenterFrame className="upper">
                            <div>
                                <span aria-label="Thinking" role="img">
                                    4🤔4
                                </span>
                            </div>
                        </CenterFrame>
                        <CenterFrame className="lower">
                            <div>{i18nData.notFound}</div>
                        </CenterFrame>
                    </div>
                </DefaultTemplate>
            ) : null}
        </>
    );
};

NotFoundPage.propTypes = {
    className: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
};

export default styled(NotFoundPage)`
    margin: 100px 0px;

    span {
        white-space: nowrap;
    }
    .lower {
        font-size: 50px;
        text-align: center;
        .inner {
            padding: 50px;
            background: ${({ theme }) => theme.overgroundColor};
        }
    }
    .upper {
        font-size: 100px;
        text-align: center;
        padding: 50px 0;
    }
`;

export const query = graphql`
    query NotFound($locale: String) {
        allDatoCmsI18N(filter: { locale: { eq: $locale } }) {
            nodes {
                ...HeaderData
                ...FooterData
                ...CookieBannerData
                notFound
            }
        }
    }
`;
